/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import user_placeholder from '../../images/user_placeholder.png';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeholder from '../../images/placeholder.jpg'
import { NavLink } from 'react-router-dom';

export default class Instructor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: null
        };
    }


    async componentDidMount() {
        this.getAllCMS('Instructor');
    }


    async getAllCMS(type) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type)
            .then(res => {
                console.log("Testimonials res", res)
                this.setState({ tableData: res.data?.data?.sort((a, b) => a.order_id - b.order_id), loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });

    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="coursesComp">
                        <h2 className='text-center'>Our Instructors</h2>
                        <div className="row justify-content-center ">
                            {this.state.tableData === null ? '' : this.state.tableData.map(item => {
                                return (
                                    <React.Fragment>
                                        <div className="col-12 col-md-4 mb-3">
                                            <div className="inner textWrapper text-left">
                                                <p>{item.cms_image == 'undefined' || item.cms_image == null ? <img src={Placeholder} alt="" /> : <img src={process.env.react_app_base_url + "/" + item.cms_image} alt="" />}</p>
                                                <h3 style={{ marginBottom: '20px' }}> <div dangerouslySetInnerHTML={{ __html: item.cms_title }}></div></h3>
                                                {item.cms_tags &&
                                                    <p>
                                                        <a style={{ marginTop: "-20px", display: 'block', float: 'left' }} rel="noopener noreferrer" target="_blank" href={item?.cms_tags} fontIcon="ti-linkedin" title="">
                                                            <span className={'fontIcon  ti-linkedin'}></span>
                                                            <div className='clearfix'></div>
                                                        </a>

                                                    </p>
                                                }
                                                <div className='clearfix'></div>
                                                <div dangerouslySetInnerHTML={{ __html: item.short_description }}></div>
                                                <NavLink to={`about-us#aboutTrainers`} >
                                                    Read More
                                                </NavLink>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}
